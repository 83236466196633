import React from 'react';
import { Button, IconButton, Grid, Box, Typography, Paper } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';

const FileInput = ({ field, form }) => {
    const handleChange = (event) => {
        const files = event.currentTarget.files;
        form.setFieldValue(field.name, files);
    };

    return (
        <div>
            <input
                id="file"
                name={field.name}
                type="file"
                onChange={handleChange}
                multiple
                hidden
            />
            <label htmlFor="file">
                <Button variant="contained" component="span" startIcon={<AttachFileIcon />}>
                    Attach Files
                </Button>
            </label>
            {form.values[field.name] && form.values[field.name].length > 0 && (
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    {Array.from(form.values[field.name]).map((file, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Paper elevation={3}>
                                <Box
                                    p={2}
                                    display="flex"
                                    alignItems="center"
                                    borderRadius={2}
                                >
                                    <Box flexGrow={1}>
                                        <Typography variant="body1" noWrap>{file.name}</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {(file.size / 1024).toFixed(2)} KB
                                        </Typography>
                                    </Box>
                                    <IconButton
                                        onClick={() => {
                                            const newFiles = Array.from(form.values[field.name]);
                                            newFiles.splice(index, 1);
                                            form.setFieldValue(field.name, newFiles);
                                        }}
                                        edge="end"
                                        color="secondary"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            )}
        </div>
    );
};

export default FileInput;
