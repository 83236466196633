import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Grid,
  Button,
  Paper,
  MenuItem,
  FormControl,
  FormLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorIcon from "@mui/icons-material/Error";
import { styled } from "@mui/material/styles";
import FileInput from "./ExternalFormAssests/File";
import axios from "axios";
import { useSnackbar } from "notistack";
import { ClassicSpinner } from "react-spinners-kit";
import { BASE_URL } from "../../config";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import ReCAPTCHA from "react-google-recaptcha";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobile: Yup.string().required("Mobile is required"),
  unitNumber: Yup.string().required("Unit/House Number is required"),
  SitestreetName: Yup.string().required("Site Street Name is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  postcode: Yup.string().required("Postcode is required"),
  country: Yup.string().required("Country is required"),
  SiteCity: Yup.string().required("Site City required"),
  Sitecountry: Yup.string().required("Site Country required"),
  Sitestate: Yup.string().required("Site State required"),
  nccClassification: Yup.string().required("NCC Classification is required"),
  ownerName: Yup.string().required("Owner Name is required"),
  ownerMobile: Yup.string().required("Owner Mobile is required"),
  ownerEmail: Yup.string()
    .email("Invalid email")
    .required("Owner Email is required"),
  builderEmail: Yup.string()
    .email("Invalid email")
    .required("Builder Email is required"),
  designerEmail: Yup.string()
    .email("Invalid email")
    .required("Designer Email is required"),
  engineerEmail: Yup.string()
    .email("Invalid email")
    .required("Engineer Email is required"),
  descriptionOfWorks: Yup.string().required("Description of Works is required"),
});

const CustomPaper = styled(Paper)(({ theme }) => ({
  maxHeight: "100vh",
  padding: "16px",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "10px",
    backgroundColor: "#ccc",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#8d8dfd",
    borderRadius: "3px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#ccc",
  },

  "& .MuiAccordion-root::before": {
    backgroundColor: "transparent",
  },
}));

const MyForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [snackbarLoading, setSnackbarLoading] = useState(false);
  const siteKey = "6Ldo3KMpAAAAAPiBdeVIIYopfndok0OTiUAsHAcr"
    
  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("uid", "3c4bb38ca4488df90ae444761f8ad411");
      formData.append("service", "Dropbox");
      Object.keys(values).forEach((key) => {
        if (key === "attachments" && values[key].length > 0) {
          Array.from(values[key]).forEach((file) => {
            formData.append("files", file);
          });
        } else {
          formData.append(key, values[key]);
        }
      });

      const response = await axios.post(
        `${BASE_URL}/external/perform_solution/quote_creation`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false);
      enqueueSnackbar("Response Submitted Successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      resetForm();
    } catch (error) {
      const data = error.response.data.message;
      setLoading(false);
      enqueueSnackbar(`${data}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };
  return (
    <CustomPaper elevation={3}>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          companyName: "",
          abnNumber: "",
          email: "",
          mobile: "",
          address: "",
          city: "",
          state: "",
          postcode: "",
          country: "",
          unitNumber: "",
          SitestreetName: "",
          SiteCity: "",
          Sitecountry: "",
          Sitestate: "",
          buildingPermitNumber: "",
          nccClassification: "",
          ownerName: "",
          ownerMobile: "",
          ownerEmail: "",
          designerName: "",
          designerOrganisation: "",
          designerEmail: "",
          builderName: "",
          builderOrganisation: "",
          builderEmail: "",
          engineerName: "",
          engineerOrganisation: "",
          engineerEmail: "",
          descriptionOfWorks: "",
          hearAboutUs: "",
          message: "",
          attachments: [],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          validateForm,
          resetForm,
        }) => (
          <Form>
            <Accordion
              style={
                errors.firstName ||
                errors.lastName ||
                errors.email ||
                errors.mobile
                  ? { marginTop: "10px", borderRadius: "10px" }
                  : { marginTop: "10px", borderRadius: "10px" }
              }
            >
              <AccordionSummary
                sx={{ borderRadius: "10px", background: "#b3b3ff" }}
                expandIcon={
                  errors.firstName ||
                  errors.lastName ||
                  errors.email ||
                  errors.mobile ? (
                    <ErrorIcon
                      style={{
                        color: "orange",
                        borderRadius: "50%",
                        background: "white",
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "13.5px",
                      sm: "16px",
                      fontFamily: "Poppins",
                      color: "Black",
                      fontWeight: "800",
                      textTransform: "uppercase",
                    },
                  }}
                >
                  Personal Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} mt={0.2}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="firstName"
                      as={TextField}
                      label="First Name"
                      fullWidth
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="lastName"
                      as={TextField}
                      label="Last Name"
                      fullWidth
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="companyName"
                      as={TextField}
                      label="Company Name"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="abnNumber"
                      as={TextField}
                      label="ABN Number"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="email"
                      as={TextField}
                      label="Email"
                      fullWidth
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="mobile"
                      as={TextField}
                      label="Mobile"
                      fullWidth
                      error={touched.mobile && Boolean(errors.mobile)}
                      helperText={touched.mobile && errors.mobile}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Postal Address Accordion */}
            <Accordion
              style={
                errors.address ||
                errors.city ||
                errors.state ||
                errors.postcode ||
                errors.country
                  ? { marginTop: "10px", borderRadius: "10px" }
                  : { marginTop: "10px", borderRadius: "10px" }
              }
            >
              <AccordionSummary
                sx={{ borderRadius: "10px", background: "#b3b3ff" }}
                expandIcon={
                  errors.address ||
                  errors.city ||
                  errors.state ||
                  errors.postcode ||
                  errors.country ? (
                    <ErrorIcon
                      style={{
                        color: "orange",
                        borderRadius: "50%",
                        background: "white",
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "13.5px",
                      sm: "16px",
                      fontFamily: "Poppins",
                      color: "Black",
                      fontWeight: "800",
                      textTransform: "uppercase",
                    },
                  }}
                >
                  Postal Address
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} mt={0.2}>
                  <Grid item xs={12}>
                    <Field
                      name="address"
                      as={TextField}
                      label="Address"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <FormLabel
                        sx={{
                          color:
                            touched.country && errors.country
                              ? "#d32f2f"
                              : "rgba(0, 0, 0, 0.6)",
                        }}
                      >
                        Country
                      </FormLabel>
                      <CountryDropdown
                        value={values.country}
                        onChange={(val) => setFieldValue("country", val)}
                        style={{
                          width: "100%",
                          padding: "16.5px 14px",
                          borderRadius: "4px",
                          borderColor:
                            touched.country && errors.country
                              ? "#d32f2f"
                              : "rgba(0, 0, 0, 0.23)",
                          fontSize: "16px",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          color:
                            touched.country && errors.country
                              ? "#d32f2f"
                              : "rgba(0, 0, 0, 0.6)",
                        }}
                      />
                      {errors.country && touched.country && (
                        <Typography variant="caption" color="error" ml={2}>
                          {errors.country}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <FormLabel
                        sx={{
                          color:
                            touched.state && errors.state
                              ? "#d32f2f"
                              : "rgba(0, 0, 0, 0.6)",
                        }}
                      >
                        Region
                      </FormLabel>
                      <RegionDropdown
                        country={values.country}
                        value={values.state}
                        onChange={(val) => setFieldValue("state", val)}
                        style={{
                          width: "100%",
                          padding: "16.5px 14px",
                          borderRadius: "4px",
                          borderColor:
                            touched.state && errors.state
                              ? "#d32f2f"
                              : "rgba(0, 0, 0, 0.23)",
                          fontSize: "16px",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          color:
                            touched.state && errors.state
                              ? "#d32f2f"
                              : "rgba(0, 0, 0, 0.6)",
                        }}
                      />
                      {errors.state && touched.state && (
                        <Typography variant="caption" color="error" ml={2}>
                          {errors.state}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="city"
                      as={TextField}
                      label="City"
                      fullWidth
                      error={touched.city && Boolean(errors.city)}
                      helperText={touched.city && errors.city}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      name="postcode"
                      as={TextField}
                      label="Postcode"
                      fullWidth
                      error={touched.postcode && Boolean(errors.postcode)}
                      helperText={touched.postcode && errors.postcode}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Project Address Accordion */}
            <Accordion
              style={
                errors.unitNumber ||
                errors.SitestreetName ||
                errors.SiteCity ||
                errors.Sitecountry ||
                errors.Sitestate ||
                errors.nccClassification
                  ? { marginTop: "10px", borderRadius: "10px" }
                  : { marginTop: "10px", borderRadius: "10px" }
              }
            >
              <AccordionSummary
                sx={{ borderRadius: "10px", background: "#b3b3ff" }}
                expandIcon={
                  errors.unitNumber ||
                  errors.SitestreetName ||
                  errors.Sitecountry ||
                  errors.Sitestate ||
                  errors.SiteCity ||
                  errors.nccClassification ? (
                    <ErrorIcon
                      style={{
                        color: "orange",
                        borderRadius: "50%",
                        background: "white",
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "13.5px",
                      sm: "16px",
                      fontFamily: "Poppins",
                      color: "Black",
                      fontWeight: "800",
                      textTransform: "uppercase",
                    },
                  }}
                >
                  Project Address
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} mt={0.2}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="buildingPermitNumber"
                      as={TextField}
                      label="Building Permit Number"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="unitNumber"
                      as={TextField}
                      label="Unit/House Number"
                      fullWidth
                      error={touched.unitNumber && Boolean(errors.unitNumber)}
                      helperText={touched.unitNumber && errors.unitNumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <FormLabel
                        sx={{
                          color:
                            touched.Sitecountry && errors.Sitecountry
                              ? "#d32f2f"
                              : "rgba(0, 0, 0, 0.6)",
                        }}
                      >
                        Site Country
                      </FormLabel>
                      <CountryDropdown
                        value={values.Sitecountry}
                        onChange={(val) => setFieldValue("Sitecountry", val)}
                        style={{
                          width: "100%",
                          padding: "16.5px 14px",
                          borderRadius: "4px",
                          borderColor:
                            touched.Sitecountry && errors.Sitecountry
                              ? "#d32f2f"
                              : "rgba(0, 0, 0, 0.23)",
                          fontSize: "16px",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          color:
                            touched.Sitecountry && errors.Sitecountry
                              ? "#d32f2f"
                              : "rgba(0, 0, 0, 0.6)",
                        }}
                      />
                      {errors.Sitecountry && touched.Sitecountry && (
                        <Typography variant="caption" color="error" ml={2}>
                          {errors.Sitecountry}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <FormLabel
                        sx={{
                          color:
                            touched.Sitestate && errors.Sitestate
                              ? "#d32f2f"
                              : "rgba(0, 0, 0, 0.6)",
                        }}
                      >
                        Site Region
                      </FormLabel>
                      <RegionDropdown
                        country={values.Sitecountry}
                        value={values.Sitestate}
                        onChange={(val) => setFieldValue("Sitestate", val)}
                        style={{
                          width: "100%",
                          padding: "16.5px 14px",
                          borderRadius: "4px",
                          borderColor:
                            touched.Sitestate && errors.Sitestate
                              ? "#d32f2f"
                              : "rgba(0, 0, 0, 0.23)",
                          fontSize: "16px",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          color:
                            touched.Sitestate && errors.Sitestate
                              ? "#d32f2f"
                              : "rgba(0, 0, 0, 0.6)",
                        }}
                      />
                      {errors.Sitestate && touched.Sitestate && (
                        <Typography variant="caption" color="error" ml={2}>
                          {errors.Sitestate}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="SitestreetName"
                      as={TextField}
                      label="Site Street Name"
                      fullWidth
                      error={
                        touched.SitestreetName && Boolean(errors.SitestreetName)
                      }
                      helperText={
                        touched.SitestreetName && errors.SitestreetName
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="SiteCity"
                      as={TextField}
                      label="Site City"
                      fullWidth
                      error={
                        touched.SiteCity && Boolean(errors.SiteCity)
                      }
                      helperText={touched.SiteCity && errors.SiteCity}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      name="nccClassification"
                      as={TextField}
                      select
                      label="NCC Classification of Works"
                      fullWidth
                      error={
                        touched.nccClassification &&
                        Boolean(errors.nccClassification)
                      }
                      helperText={
                        touched.nccClassification && errors.nccClassification
                      }
                    >
                      <MenuItem value="Class 1(ai) & Class 1(ai)Single & Multi-Residential Dwellings">
                        Class 1(ai) & Class 1(ai)Single & Multi-Residential
                        Dwellings
                      </MenuItem>
                      <MenuItem value="Class 1b Boarding Homes / Rooming Homes">
                        Class 1b Boarding Homes / Rooming Homes
                      </MenuItem>
                      <MenuItem value="Class 2 Apartment Buildings">
                        Class 2 Apartment Buildings
                      </MenuItem>
                      <MenuItem value="Class 3 Hostel / Backpackers">
                        Class 3 Hostel / Backpackers
                      </MenuItem>
                      <MenuItem value="Class 3 Accommodation for children or people with disabilities">
                        Class 3 Accommodation for children or people with
                        disabilities
                      </MenuItem>
                      <MenuItem value="Class 5 Office Buildings">
                        Class 5 Office Buildings
                      </MenuItem>
                      <MenuItem value="Retail Buildings including Cafes">
                        Retail Buildings including Cafes
                      </MenuItem>
                      <MenuItem value="Restaurants">Restaurants</MenuItem>
                      <MenuItem value="Class 6 Showrooms">
                        Class 6 Showrooms
                      </MenuItem>
                      <MenuItem value="Class 7b Warehouse / Wholesale Buildings">
                        Class 7b Warehouse / Wholesale Buildings
                      </MenuItem>
                      <MenuItem value="Class 8 Factory Buildings">
                        Class 8 Factory Buildings
                      </MenuItem>
                      <MenuItem value="Class 9b Schools / Kindergartens / Churches / Sporting Clubs">
                        Class 9b Schools / Kindergartens / Churches / Sporting
                        Clubs
                      </MenuItem>
                      <MenuItem value="Class 9b Nightclubs / Bars">
                        Class 9b Nightclubs / Bars
                      </MenuItem>
                      <MenuItem value="Class 10 Garage / Carport / Shed / Fences / Swimming Pools & Safety Barriers / Verandas / Pergolas / Decks">
                        Class 10 Garage / Carport / Shed / Fences / Swimming
                        Pools & Safety Barriers / Verandas / Pergolas / Decks
                      </MenuItem>
                      <MenuItem value="Class 1a A detached house or One of a group of two or more attached dwellings">
                        Class 1a A detached house or One of a group of two or
                        more attached dwellings
                      </MenuItem>
                    </Field>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Owner Details Accordion */}
            <Accordion
              style={
                errors.ownerName || errors.ownerMobile || errors.ownerEmail
                  ? { marginTop: "10px", borderRadius: "10px" }
                  : { marginTop: "10px", borderRadius: "10px" }
              }
            >
              <AccordionSummary
                sx={{ borderRadius: "10px", background: "#b3b3ff" }}
                expandIcon={
                  errors.ownerName ||
                  errors.ownerMobile ||
                  errors.ownerEmail ? (
                    <ErrorIcon
                      style={{
                        color: "orange",
                        borderRadius: "50%",
                        background: "white",
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "13.5px",
                      sm: "16px",
                      fontFamily: "Poppins",
                      color: "Black",
                      fontWeight: "800",
                      textTransform: "uppercase",
                    },
                  }}
                >
                  Owner Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} mt={0.2}>
                  <Grid item xs={12} sm={4}>
                    <Field
                      name="ownerName"
                      as={TextField}
                      label="Owner Name"
                      fullWidth
                      error={touched.ownerName && Boolean(errors.ownerName)}
                      helperText={touched.ownerName && errors.ownerName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      name="ownerMobile"
                      as={TextField}
                      label="Owner Mobile"
                      fullWidth
                      error={touched.ownerMobile && Boolean(errors.ownerMobile)}
                      helperText={touched.ownerMobile && errors.ownerMobile}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      name="ownerEmail"
                      as={TextField}
                      label="Owner Email"
                      fullWidth
                      error={touched.ownerEmail && Boolean(errors.ownerEmail)}
                      helperText={touched.ownerEmail && errors.ownerEmail}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Designer Details Accordion */}
            <Accordion
              style={
                errors.designerEmail
                  ? { marginTop: "10px", borderRadius: "10px" }
                  : { marginTop: "10px", borderRadius: "10px" }
              }
            >
              <AccordionSummary
                sx={{ borderRadius: "10px", background: "#b3b3ff" }}
                expandIcon={
                  errors.designerEmail ? (
                    <ErrorIcon
                      style={{
                        color: "orange",
                        borderRadius: "50%",
                        background: "white",
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "13.5px",
                      sm: "16px",
                      fontFamily: "Poppins",
                      color: "Black",
                      fontWeight: "800",
                      textTransform: "uppercase",
                    },
                  }}
                >
                  Designer Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} mt={0.2}>
                  <Grid item xs={12} sm={4}>
                    <Field
                      name="designerName"
                      as={TextField}
                      label="Designer Name"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      name="designerOrganisation"
                      as={TextField}
                      label="Designer Organisation"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      name="designerEmail"
                      as={TextField}
                      label="Designer Email"
                      fullWidth
                      error={
                        touched.designerEmail && Boolean(errors.designerEmail)
                      }
                      helperText={touched.designerEmail && errors.designerEmail}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Builder Details Accordion */}
            <Accordion
              style={
                errors.builderEmail
                  ? { marginTop: "10px", borderRadius: "10px" }
                  : { marginTop: "10px", borderRadius: "10px" }
              }
            >
              <AccordionSummary
                sx={{ borderRadius: "10px", background: "#b3b3ff" }}
                expandIcon={
                  errors.builderEmail ? (
                    <ErrorIcon
                      style={{
                        color: "orange",
                        borderRadius: "50%",
                        background: "white",
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "13.5px",
                      sm: "16px",
                      fontFamily: "Poppins",
                      color: "Black",
                      fontWeight: "800",
                      textTransform: "uppercase",
                    },
                  }}
                >
                  Builder Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} mt={0.2}>
                  <Grid item xs={12} sm={4}>
                    <Field
                      name="builderName"
                      as={TextField}
                      label="Builder Name"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      name="builderOrganisation"
                      as={TextField}
                      label="Builder Organisation"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      name="builderEmail"
                      as={TextField}
                      label="Builder Email"
                      fullWidth
                      error={
                        touched.builderEmail && Boolean(errors.builderEmail)
                      }
                      helperText={touched.builderEmail && errors.builderEmail}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Engineer Details Accordion */}
            <Accordion
              style={
                errors.engineerEmail
                  ? { marginTop: "10px", borderRadius: "10px" }
                  : { marginTop: "10px", borderRadius: "10px" }
              }
            >
              <AccordionSummary
                sx={{ borderRadius: "10px", background: "#b3b3ff" }}
                expandIcon={
                  errors.engineerEmail ? (
                    <ErrorIcon
                      style={{
                        color: "orange",
                        borderRadius: "50%",
                        background: "white",
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "13.5px",
                      sm: "16px",
                      fontFamily: "Poppins",
                      color: "Black",
                      fontWeight: "800",
                      textTransform: "uppercase",
                    },
                  }}
                >
                  Engineer Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} mt={0.2}>
                  <Grid item xs={12} sm={4}>
                    <Field
                      name="engineerName"
                      as={TextField}
                      label="Engineer Name"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      name="engineerOrganisation"
                      as={TextField}
                      label="Engineer Organisation"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      name="engineerEmail"
                      as={TextField}
                      label="Engineer Email"
                      fullWidth
                      error={
                        touched.engineerEmail && Boolean(errors.engineerEmail)
                      }
                      helperText={touched.engineerEmail && errors.engineerEmail}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Other Details Accordion */}
            <Accordion
              style={
                errors.descriptionOfWorks
                  ? { marginTop: "10px", borderRadius: "10px" }
                  : { marginTop: "10px", borderRadius: "10px" }
              }
            >
              <AccordionSummary
                sx={{ borderRadius: "10px", background: "#b3b3ff" }}
                expandIcon={
                  errors.descriptionOfWorks ? (
                    <ErrorIcon
                      style={{
                        color: "orange",
                        borderRadius: "50%",
                        background: "white",
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "13.5px",
                      sm: "16px",
                      fontFamily: "Poppins",
                      color: "Black",
                      fontWeight: "800",
                      textTransform: "uppercase",
                    },
                  }}
                >
                  Other Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} mt={0.2}>
                  <Grid item xs={12}>
                    <Field
                      name="descriptionOfWorks"
                      as={TextField}
                      label="Description of Works"
                      fullWidth
                      multiline
                      rows={4}
                      error={
                        touched.descriptionOfWorks &&
                        Boolean(errors.descriptionOfWorks)
                      }
                      helperText={
                        touched.descriptionOfWorks && errors.descriptionOfWorks
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="hearAboutUs"
                      as={TextField}
                      label="How did you hear about us?"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="message"
                      as={TextField}
                      label="Message (optional)"
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ borderRadius: "10px", marginTop: "10px" }}>
              <AccordionSummary
                sx={{ borderRadius: "10px", background: "#b3b3ff" }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "13.5px",
                      sm: "16px",
                      fontFamily: "Poppins",
                      color: "Black",
                      fontWeight: "800",
                      textTransform: "uppercase",
                    },
                  }}
                >
                  Attachments
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} mt={0.2}>
                  <Grid item xs={12}>
                    <Field name="attachments" component={FileInput} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            {/* Submit Button */}
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <Grid>
                <ReCAPTCHA
                  sitekey={siteKey}
                  onChange={handleCaptchaChange}
                />
              </Grid>
              <Grid mt={2}>
                <Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    // disabled={!captchaVerified || loading || snackbarLoading}
                    onClick={async () => {
                      setSnackbarLoading(true);
                      const validationErrors = await validateForm();
                      if (Object.keys(validationErrors).length > 0) {
                        for (const error of Object.values(validationErrors)) {
                          enqueueSnackbar(error, {
                            variant: "error",
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "right",
                            },
                          });
                          await new Promise((resolve) =>
                            setTimeout(resolve, 1000)
                          );
                        }
                        setSnackbarLoading(false);
                      }
                    }}
                  >
                    {loading ? (
                      <ClassicSpinner size={15} color="#fff" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(255, 255, 255, 0.75)",
              zIndex: 9998,
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
            }}
          >
            <ClassicSpinner size={30} thickness={10} color="blue" />
          </div>
        </div>
      )}
    </CustomPaper>
  );
};

export default MyForm;
